<template>
  <svg
    width="35"
    height="32"
    viewBox="0 0 35 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.46914 5.19427L18.5707 10.381C18.805 10.5013 19.0766 10.5245 19.3287 10.4481C20.041 10.2323 21.5914 9.77853 22.8651 9.51546C23.7389 9.32521 24.5272 9.42744 25.0883 9.57235C25.5916 9.70236 25.7824 10.2534 25.4667 10.6664C25.1192 11.121 24.571 11.6799 23.7791 12.0711C22.6236 12.6418 21.1287 13.2507 20.4361 13.5261C20.1904 13.6239 19.9922 13.8125 19.8845 14.054L15.3172 24.2954C15.2039 24.5493 14.99 24.7447 14.7268 24.8345L14.2286 25.0045C13.5103 25.2496 12.7915 24.6366 12.9201 23.8886L14.0653 17.2285L14.0692 17.2062C14.0948 17.0618 14.341 15.4913 12.8984 15.8786C11.6947 16.193 9.05343 16.9564 8.005 17.261C7.72373 17.3427 7.49437 17.5434 7.37447 17.8106L6.33551 20.1261C6.2218 20.3796 6.0078 20.5743 5.74479 20.6636L5.69105 20.6819C5.01687 20.9109 4.32612 20.3819 4.37143 19.6714L4.49457 17.7404C4.51234 17.4617 4.41274 17.1882 4.21987 16.9862L3.05073 15.7617C2.55739 15.245 2.76075 14.3914 3.43407 14.1527C3.67027 14.0689 3.92951 14.0783 4.15903 14.179L6.44817 15.1827C6.69487 15.2909 6.9731 15.2957 7.22278 15.1946C8.2058 14.7965 10.7942 13.7468 11.9492 13.2625C13.3248 12.6775 12.1387 11.6048 12.0276 11.5082L12.0105 11.4934L6.89012 6.99323C6.31314 6.48614 6.49959 5.54487 7.2263 5.29602L7.68841 5.13778C7.94552 5.04974 8.22738 5.07014 8.46914 5.19427Z" fill="#193560" />
    <circle
      cx="26.7861"
      cy="20.4736"
      r="5.74706"
      fill="#D1D7DF"
    />
    <path d="M26.4029 24.4497C26.1267 24.4497 25.9029 24.2258 25.9029 23.9497V21.7699C25.9029 21.4938 25.679 21.2699 25.4029 21.2699H23.3047C23.0285 21.2699 22.8047 21.0461 22.8047 20.7699V20.0741C22.8047 19.7979 23.0285 19.5741 23.3047 19.5741H25.4029C25.679 19.5741 25.9029 19.3502 25.9029 19.0741V16.9922C25.9029 16.716 26.1267 16.4922 26.4029 16.4922H27.164C27.4401 16.4922 27.664 16.716 27.664 16.9922V19.0741C27.664 19.3502 27.8878 19.5741 28.164 19.5741H30.2622C30.5383 19.5741 30.7622 19.7979 30.7622 20.0741V20.7699C30.7622 21.0461 30.5383 21.2699 30.2622 21.2699H28.164C27.8878 21.2699 27.664 21.4938 27.664 21.7699V23.9497C27.664 24.2258 27.4401 24.4497 27.164 24.4497H26.4029Z" fill="#193560" />
  </svg>
</template>

<script>
export default {
  name: 'OVHC'
}
</script>

<style scoped>

</style>
